/* latin-ext */
@font-face {
  font-family: '__Figtree_1bb7c9';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/test/3.75.17/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_1bb7c9';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(https://cdn.pars.doe.gov/cdn/test/3.75.17/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_1bb7c9';src: local("Arial");ascent-override: 94.32%;descent-override: 24.82%;line-gap-override: 0.00%;size-adjust: 100.72%
}.__className_1bb7c9 {font-family: '__Figtree_1bb7c9', '__Figtree_Fallback_1bb7c9';font-style: normal
}

